<template>
  <v-col class="d-flex flex-column height-100 pa-0">
    <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        Company Reputation
      </span>
      <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closeAdditionalInfo')">mdi-chevron-right</v-icon>
    </v-row>
    <v-row class="ma-0 flex-grow-1 scroll">
      <v-col style="padding: 30px !important;">
        <p class="fz-14 font-weight-light text-black">{{ companyInfo.reputation }}</p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  props: ['companyInfo']
}
</script>

<style scoped>

</style>
