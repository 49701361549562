<template>
  <v-col class="d-flex flex-column height-100 pa-0">
    <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        Company Details
      </span>
      <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closeAllInformation')">mdi-chevron-right</v-icon>
    </v-row>
    <v-row class="ma-0 flex-grow-1 scroll">
        <v-col class="d-flex flex-column height-100 pa-0">
          <v-row class="ma-0 flex-grow-1 scroll">
            <v-form ref="form" class="flex-grow-1">
              <v-col style="padding: 30px !important;">
                <!--Company SECTION-->
                <p class="fz-14 black--text font-weight-bold">Company details</p>
                <v-divider class="my-4 black bb-1"></v-divider>
                <!--Legal address-->
                <p class="fz-14 mb-1 text-gray">Legal address</p>
                <v-textarea
                  outlined
                  v-model="companyDetails.legalAddress"
                  disabled
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  height="80"
                  no-resize
                ></v-textarea>
                <!--INN-->
                <p class="fz-14 mb-1 text-gray">INN</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.inn"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <!--OGRN-->
                <p class="fz-14 mb-1 text--secondary">OGRN</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.ogrn"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                ></v-text-field>
                <!--Account (Расчётный счёт)-->
                <p class="fz-14 mb-1 text--secondary">Checking account</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.account"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                />
                <!--BANK SECTION-->
                <p class="fz-14 black--text font-weight-bold">Bank details</p>
                <v-divider class="my-4 black bb-1"></v-divider>
                <!--Bank name-->
                <p class="fz-14 mb-1 text--secondary">Bank name</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.bank"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                />
                <!--Bank INN-->
                <p class="fz-14 mb-1 text--secondary">Bank INN</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.bankInn"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                />
                <!--Bank BIK-->
                <p class="fz-14 mb-1 text--secondary">Bank BIK</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.bankBik"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                />
                <!--Correspondent account-->
                <p class="fz-14 mb-1 text--secondary">Correspondent account of bank</p>
                <v-text-field
                  outlined
                  single-line
                  disabled
                  v-model="companyDetails.corAccount"
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  dense
                />
                <!--Legal address of bank-->
                <p class="fz-14 mb-1 text--secondary">Legal address of bank</p>
                <v-textarea
                  outlined
                  v-model="companyDetails.bankLegalAddress"
                  disabled
                  class="rounded-lg mt-1 fz-14 font-weight-medium input-border text-black"
                  height="80"
                  no-resize
                />
              </v-col>
            </v-form>
          </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'CompanyAllInformation',
  props: {
    companyDetails: {
      type: Object
    },
    readOnly: {
      type: Boolean
    }
  }
}
</script>

<style scoped>

</style>
