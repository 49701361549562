import { render, staticRenderFns } from "./CompanyAdditionalInfo.vue?vue&type=template&id=510a5776&scoped=true&"
import script from "./CompanyAdditionalInfo.vue?vue&type=script&lang=js&"
export * from "./CompanyAdditionalInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510a5776",
  null
  
)

export default component.exports